import styled from '@emotion/styled'
import {
  BigWheelFreeRoll,
  BigWheelRoundedRectangle,
  BigWheelStar,
} from 'assets/images';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  height: 100%;
  background-image: url(${BigWheelFreeRoll});
  background-size: 100% 63px;
  position: relative;
  padding-left: 13px;
`;

export const MeterSquaresContainer = styled.div`
  display: flex;
  height: 20px;
  background-color: #003200;
  margin-bottom: 10px;
  margin-left: 62px;
`;

export const MeterSquaresEmpty = styled.div`
  height: 20px;
  width: 22px;
  ${props => 
    props.active ? `
      background-image: url(${BigWheelRoundedRectangle});
    ` : `
      background: #022402;
    `
  }
  background-size: 100% 100%;
  margin-left: 2px;
`;

export const GreenContainer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Star = styled.div`
  background-image: url(${BigWheelStar});
  background-size: 100% 100%;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -25px;
  margin-right: -30px;
`;